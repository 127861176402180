import React from 'react';
import { Button } from 'antd';
import { useState } from 'react';
import { useEffect } from 'react';
import './index.css'

import { useSetChou5Mutation } from '../../store/pageApi';
import { useSetChou1Mutation } from '../../store/pageApi';
const Admin = () => {
    const [start5, setStart5] = useState(false)
    const [start1, setStart1] = useState(false)
 
   
    const [setChou5]=useSetChou5Mutation()
    const [setChou1]=useSetChou1Mutation()
    useEffect(() => {
        setChou5(
            {
                status:0
            }
        ).then(res=>{
            setStart5(res.data)
            console.log(res.data)
        })
        setChou1(
            {
                status:0
            }
        ).then(res=>{
            setStart1(res.data)
            console.log(res.data)
        })
    }, [])
    const startHandle5 = (num) => {

        if (num === 1) {
             setChou5(
                {
                    status:1
                }
             ).then(res=>{
                console.log(res.data)
                if(res.data==1){
                    setStart5(true)
                 }
            })
        } else if (num === 0) {
            setChou5(
                {
                    status:2
                }
             ).then(res=>{
                console.log(res.data)
                if(res.data==2){
                   setStart5(false)
                }
            })
        }
    }

    const startHandle1 = (num) => {

        if (num === 1) {
             setChou1(
                {
                    status:1
                }
             ).then(res=>{
                console.log(res.data)
                if(res.data==1){
                    setStart1(true)
                 }
            })
        } else if (num === 0) {
            setChou1(
                {
                    status:2
                }
             ).then(res=>{
                console.log(res.data)
                if(res.data==2){
                   setStart1(false)
                }
            })
        }
    }
    return (
        <div style={{
            textAlign: 'center'
        }}>
            {
                start5==1 ?
                    <Button type="primary" onClick={() => startHandle5(0)} className='btn5 stop'>
                        停止抽”赋能大礼包“
                    </Button>
                    :
                    <Button type="primary" onClick={() => startHandle5(1)} className='btn5'>
                        开始抽”赋能大礼包“
                    </Button>
            }
            {
                start1==1 ?
                    <Button type="primary" onClick={() => startHandle1(0)} className='btn1 stop'>
                    停止抽 理想 MEGA使用权
                    </Button>
                    :
                    <Button type="primary" onClick={() => startHandle1(1)} className='btn1'>
                    开始抽 理想 MEGA使用权
                    </Button>
            }
        </div>
    );
}

export default Admin;
