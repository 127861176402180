import { configureStore } from "@reduxjs/toolkit";
import pageApi from "./pageApi";


const store=configureStore({
    reducer:{
        pageApi:pageApi.reducer,
    },
    middleware:(get)=>
    get().concat(
        pageApi.middleware,
    )
})

export default store