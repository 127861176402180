import React from 'react';
import './index.css'
import { useState } from 'react';
import { useEffect } from 'react';
import { useGetChou5Mutation } from '../../store/pageApi';
import { useGetInfoQuery } from '../../store/pageApi';
import { useGetChou5staticQuery } from '../../store/pageApi';
import { Typography } from 'antd';

const { Title } = Typography;

const Home = () => {

    const [name1, setName1] = useState('')
    const [name2, setName2] = useState('')
    const [name3, setName3] = useState('')
    const [name4, setName4] = useState('')
    const [name5, setName5] = useState('')
    const [jishi, setJishi] = useState(0)
    const [status, setStatus] = useState(0)
    const [getChou5] = useGetChou5Mutation()
    const { data } = useGetInfoQuery()

    const gundong = () => {
        setTimeout(() => {
            console.log(data)

            let newTodos = data.filter((todoObj) => {
                return todoObj.company && todoObj.name
            })

            let shuju = [...newTodos]
            let count = shuju.length

            let suiji1 = Math.round(Math.random() * (count - 1));
            setName1(shuju[suiji1])
            shuju.splice(suiji1, 1)

            let suiji2 = Math.round(Math.random() * (count - 2));
            setName2(shuju[suiji2])
            shuju.splice(suiji2, 1)


            let suiji3 = Math.round(Math.random() * (count - 3));
            setName3(shuju[suiji3])

            shuju.splice(suiji3, 1)


            let suiji4 = Math.round(Math.random() * (count - 4));
            setName4(shuju[suiji4])
            shuju.splice(suiji4, 1)


            let suiji5 = Math.round(Math.random() * (count - 5));
            setName5(shuju[suiji5])

            setJishi(state => state + 1)

        }, 20)
    }
    const gundong2 = () => {

        setTimeout(() => {
            let newTodos = data.filter((todoObj) => {
                console.log(todoObj.gift)
                return todoObj.company && todoObj.name && todoObj.gift==1
            })
            
    
            if (newTodos.length > 0) {
                console.log('内定')
                let shuju = [...newTodos]
                let count = shuju.length
                console.log(shuju)
                console.log(newTodos)
    
                let suiji1 = Math.round(Math.random() * (count - 1));
                setName1(shuju[suiji1])
                console.log(shuju[suiji1])
                console.log(suiji1)
                console.log(shuju)
                shuju.splice(suiji1, 1)
    
                let suiji2 = Math.round(Math.random() * (count - 2));
                setName2(shuju[suiji2])
                console.log(shuju[suiji2])
                console.log(shuju)
                console.log(suiji2)
                shuju.splice(suiji2, 1)
    
    
                let suiji3 = Math.round(Math.random() * (count - 3));
                setName3(shuju[suiji3])
                console.log(shuju[suiji3])
                console.log(shuju)
                console.log(suiji3)
                shuju.splice(suiji3, 1)
    
    
                let suiji4 = Math.round(Math.random() * (count - 4));
                setName4(shuju[suiji4])
                console.log(shuju[suiji4])
                console.log(shuju)
                console.log(suiji4)
                shuju.splice(suiji4, 1)
    
    
                let suiji5 = Math.round(Math.random() * (count - 5));
                setName5(shuju[suiji5])
                console.log(suiji5)
            }

        }, 50)
        
        

    }

    useEffect(() => {
        document.title = '赋能大礼包'
        if (status === 1) {
            gundong()
        } else if (status === 2) {
            console.log('status===2')
            gundong2()
        } else {

        }
    }, [jishi])

    useEffect(() => {
        if (data) {
            setInterval(() => {
                getChou5().then(res => {
                    setStatus(res.data)
                    if (res.data === 1) {
                        gundong()
                    }
                })
            }, 1000)
        }
    }, [data])



    return (
        <div style={{
            backgroundImage: "url('/img/bg3.jpg')",
            backgroundRepeat: 'no-repeat',
            backgroundSize: '750rem',
            width: '750rem',
            height: '375rem',
            textAlign: 'center',

        }} className='home3'>

            <div>
                <Title className='title'>赋能大礼包</Title>
                <div className='list'>
                {
                    jishi ? <>
                        <Title level={2} className='name'><span>{name1.company}</span></Title>
                        <Title level={2} className='name'><span>{name2.company}</span></Title>
                        <Title level={2} className='name'><span>{name3.company}</span></Title>
                        <Title level={2} className='name'><span>{name4.company}</span></Title>
                        <Title level={2} className='name'><span>{name5.company}</span></Title>
                    </>
                        : null 
                }
                </div>
                

            </div>
        </div>

    );
}

export default Home;
