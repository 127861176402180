import React from 'react';
import { useRoutes } from 'react-router-dom';
import Admin from '../pages/admin';

import Home from '../pages/home';
import Home2 from '../pages/home2';
import Home3 from '../pages/home3';
import Home4 from '../pages/home4';

const Routes = () => {
    const element = useRoutes([
       
        {
            path: 'gift',
            element: <Home3 />,
        },
        {
            path: 'mega',
            element: <Home4 />,
        },
        {
            path: 'home3',
            element: <Home3 />,
        },
        {
            path: 'home4',
            element: <Home4 />,
        },
        {
            path: 'admin',
            element: <Admin />,
        }
    ])
    return element
}

export default Routes;
