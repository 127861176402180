import React from 'react';
import './index.css'
import { useState } from 'react';
import { useEffect } from 'react';
import { useGetChou1Mutation } from '../../store/pageApi';
import { useGetInfoQuery } from '../../store/pageApi';
import { Typography } from 'antd';

const { Title } = Typography;

const Home = () => {

    const [name1, setName1] = useState('')
    const [name2, setName2] = useState('')
    const [name3, setName3] = useState('')
    const [name4, setName4] = useState('')
    const [name5, setName5] = useState('')
    const [count, setCount] = useState(1)
    const [jishi, setJishi] = useState(0)
    const [status, setStatus] = useState(0)
    const [getChou1] = useGetChou1Mutation()
    const { data } = useGetInfoQuery()


    const gundong = () => {
        setTimeout(() => {
            let newTodos = data.filter((todoObj) => {
                return todoObj.company && todoObj.name
            })

            let shuju = [...newTodos]
            let count = shuju.length

            let suiji1 = Math.round(Math.random() * (count - 1));
            setName1(shuju[suiji1])
            shuju.splice(suiji1, 1)

            let suiji2 = Math.round(Math.random() * (count - 2));
            setName2(shuju[suiji2])
            shuju.splice(suiji2, 1)


            let suiji3 = Math.round(Math.random() * (count - 3));
            setName3(shuju[suiji3])

            shuju.splice(suiji3, 1)


            let suiji4 = Math.round(Math.random() * (count - 4));
            setName4(shuju[suiji4])
            shuju.splice(suiji4, 1)


            let suiji5 = Math.round(Math.random() * (count - 5));
            setName5(shuju[suiji5])

            setJishi(state => state + 1)

        }, 20)
    }

    const gundong2 = (cishu) => {
        setTimeout(() => {
            console.log(data)

            if (cishu == 1) {
                let newTodos = data.filter((todoObj) => {
                    return todoObj.gift == 2
                })
                console.log(count)
                console.log(cishu)
                console.log(newTodos)
                let shuju = newTodos
                setName1(shuju[0])

                setCount(2)
            } else {
                let newTodos = data.filter((todoObj) => {
                    return todoObj.gift == 3
                })
                console.log(newTodos)
                let shuju = newTodos
                setName1(shuju[0])
                setCount(1)
            }
        }, 50)
    }

    useEffect(() => {
        document.title = '理想 MEGA使用权'
        if (status === 1) {
            gundong()
        } else if (status === 2) {
            console.log('status===2')
            gundong2(count)
        } else {

        }
    }, [jishi])

    useEffect(() => {
        if (data) {
            setInterval(() => {
                getChou1().then(res => {
                    setStatus(res.data)
                    if (res.data === 1) {
                        gundong()
                    }
                })
            }, 1000)
        }
    }, [data])



    return (
        <div style={{
            backgroundImage: "url('/img/bg3.jpg')",
            backgroundRepeat: 'no-repeat',
            backgroundSize: '750rem',
            width: '750rem',
            height: '375rem',
            textAlign: 'center',

        }} className='home2'>

            <div>
                <Title className='title'>理想 MEGA使用权</Title>
                <div className="list">
                    {
                        jishi ? <>

                            {
                                status == 2 ? <>
                                    <Title level={2} className='name'><span>{name1.company}</span></Title>
                                </> : <>
                                    <Title level={2} className='name'><span>{name1.company}</span></Title>
                                    <Title level={2} className='name'><span>{name2.company}</span></Title>
                                    <Title level={2} className='name'><span>{name3.company}</span></Title>
                                    <Title level={2} className='name'><span>{name4.company}</span></Title>
                                    <Title level={2} className='name'><span>{name5.company}</span></Title>
                                </>
                            }
                        </>
                            : null
                    }
                </div>


            </div>
        </div>

    );
}

export default Home;
