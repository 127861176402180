import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/dist/query/react'

const pageApi = createApi({
    reducerPath: 'pageApi',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://h5.aiweber.cn/lixiang/v2'
    }),
    endpoints(build) {
        return {
            getInfo: build.query({
                query() {
                    return 'choujiang';
                }
            }),
            getChou5: build.mutation({
                query() {
                    return {
                        url: 'get-chou5',
                        method: 'get',
                    }
                },
            }),
            setChou5: build.mutation({
                query(data) {
                    return {
                        url: 'set-chou5',
                        method: 'post',
                        body: {...data}
                    }
                },
            }),
            getChou5static: build.query({
                query() {
                    return {
                        url: 'get-chou5',
                        method: 'get',
                    }
                },
            }),

            getChou1: build.mutation({
                query() {
                    return {
                        url: 'get-chou1',
                        method: 'get',
                    }
                },
            }),
            setChou1: build.mutation({
                query(data) {
                    return {
                        url: 'set-chou1',
                        method: 'post',
                        body: {...data}
                    }
                },
            }),
            getChou1static: build.query({
                query() {
                    return {
                        url: 'get-chou1',
                        method: 'get',
                    }
                },
            }),
            
        }
    }
})

export const {
    useGetInfoQuery,
    useGetChou5Mutation,
    useSetChou5Mutation,
    useGetChou5staticQuery,
    useGetChou1Mutation,
    useSetChou1Mutation,
    useGetChou1staticQuery
} = pageApi

export default pageApi